<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col id="driver" cols="3" sm="3" md="3">
        <v-row>
          <v-card width="100%" elevation="0" color="#E5E5E5">
            <v-btn color="expansionPanels" elevation="0" class="active">
              <v-icon color="black">mdi-cart</v-icon>
              View Cart
            </v-btn>
            <v-btn color="expansionPanels" elevation="0" to="/campaigns">
              <v-icon color="black">mdi-compass</v-icon>
              Create Campaign
            </v-btn>
          </v-card>
        </v-row>
      </v-col>

      <v-col cols="9" sm="9" md="9" class="pa-1">
        <v-toolbar height="34" flat color="expansionPanels" class="my-1">
          <v-spacer />
          <v-toolbar-title class="black--text">Billboards Cart</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <div v-if="getBriefsCart" id="priceDriver">
          <v-row>
            <div>Brief Budget: {{ getBriefsCart.budget }} R</div>
          </v-row>
          <v-row>
            <div>Total Billboards Prices: {{ totalBbsPrice() }} R</div>
          </v-row>
          <v-row>Price Difference: {{ budgetDiff() }} R</v-row>
        </div>

        <v-progress-linear
          color="deep-purple accent-4"
          :active="exportProgress"
          :indeterminate="exportProgress"
        ></v-progress-linear>

        <v-card v-if="billboards.length">
          <v-data-table
            :items="billboards"
            :headers="headers"
            sort-by="name"
            class="elevation-1 pa-3"
            :loading="!billboards.length"
            loading-text="Fetching Billboards... Please wait"
            no-data-text="There are no billboards in the cart"
          >
            <template v-slot:[`item.number`]="{ _, index }">
              {{ _ }} {{ index + 1 }}
            </template>

            <template v-slot:[`item.images`]="{ item }">
              <div class="p-2">
                <div v-viewer v-if="item.images.length" class="images">
                  <img
                    alt="Billboard Image"
                    class="image"
                    v-for="img in item.images"
                    :key="img.id"
                    :src="img.path"
                    :lazy-src="img.path"
                  />
                </div>
                <div v-viewer v-else class="images">
                  <img
                    class="white--text align-end image"
                    src="/images/billboard.jpg"
                    alt="Billboard Image"
                  />
                </div>
              </div>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="error" title="Delete" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>

            <template slot="no-data"> NO DATA HERE!</template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn width="200" to="/campaigns" outlined>
              Continue Browsing</v-btn
            >
            <v-btn
              color="secondary"
              v-if="getBbCart"
              @click="checkOut"
              width="200"
              >Buy Billboards</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showCheckoutForm" width="300">
      <v-card elevation="0" class="py-5 px-2 mx-auto">
        <v-card-subtitle>One Last step</v-card-subtitle>
        <v-progress-linear
          color="deep-purple accent-4"
          :active="savePlanLoader"
          :indeterminate="savePlanLoader"
        ></v-progress-linear>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  v-model.trim="planDetails.plan_name"
                  label="Campaign Name"
                  :rules="rules.genRule"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <div v-if="!getBriefsCart">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model.trim="planDetails.brand"
                    label="Brand Name"
                    :rules="rules.genRule"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model.trim="planDetails.estimate"
                    label="Campaign Budget"
                    :rules="rules.genRule"
                    suffix="R"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col>
                      <v-menu
                        v-model="startMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="planDetails.start_date"
                            label="Start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.genRule"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="planDetails.start_date"
                          @change="startMenu = false"
                          :min="nowDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                    <v-col>
                      <v-menu
                        v-model="endMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="planDetails.end_date"
                            label="End date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :rules="rules.genRule"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="planDetails.end_date"
                          :min="nowDate"
                          @change="endMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn max-width="70" @click="showCheckoutForm = false"
                >Cancel</v-btn
              >
              <v-btn max-width="70" @click="submitPlan" color="secondary"
                >Save</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { handleHttpError } from "@/helpers/utils.js";
import { reportSuccess } from "@/helpers/utils";

export default {
  data() {
    return {
      loading: false,

      exportProgress: false,

      billboards: [],

      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },
        { text: "Image", sortable: false, value: "images" },
        { text: "Price(R)", value: "rate_card" },
        {
          text: "Length (Metres)",
          value: "length",
        },
        { text: "Width (Metres)", value: "width" },
        { text: "Height", value: "height" },
        { text: "Orientation", value: "orientation" },
        { text: "Site Run Up(m)", value: "site_run_up" },
        { text: "Address", value: "address" },

        { text: "Actions", value: "actions", sortable: false },
      ],
      mode: null,

      planDetails: {
        plan_name: null,
        estimate: 0,
        start_date: null,
        end_date: null,
        brand: "",
      },

      startMenu: false,
      endMenu: false,
      nowDate: new Date().toISOString().slice(0, 10),

      savePlanLoader: false,
      valid: false,
      showCheckoutForm: false,
      rules: {
        genRule: [(v) => !!v || "Please fill this."],
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "getCurrentUser",
      getBbCart: "billboards/getBbCart",
      getBriefsCart: "briefs/getBriefsCart",
    }),
  },

  methods: {
    ...mapActions({
      fetchBB: "billboards/fetchBB",
      fetchBbCart: "billboards/fetchBbCart",
      removeBbFromCart: "billboards/removeBbFromCart",
      addCampaign: "campaigns/addCampaign",
    }),

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toDateString();
    },

    async getBillboards() {
      if (!this.getBbCart) {
        this.loading = false;
        return handleHttpError("Cart is currently empty");
      } else {
        this.billboards = [];
        for (let bbID of this.getBbCart) {
          let bb = await this.fetchBB(bbID);
          this.billboards.push(bb);
        }
        return this.billboards;
      }
    },

    totalBbsPrice() {
      const total = this.billboards.reduce((acc, bb) => {
        let price = parseInt(bb.rate_card);
        if (!price) {
          price = 0;
        }
        return acc + price;
      }, 0);
      if (!total) return 0;
      return total;
    },

    budgetDiff() {
      const briefBudg = parseInt(this.getBriefsCart.budget) || 0;
      const totalBBPrice = this.totalBbsPrice();

      const diff = briefBudg - totalBBPrice;

      if (Math.sign(diff) > 0) {
        return `${diff}`;
      } else {
        return `-${diff}`;
      }
    },

    async deleteItem(bb) {
      await this.removeBbFromCart(bb.id);
      await this.getBillboards();
    },

    async checkOut() {
      this.showCheckoutForm = true;
    },

    async submitPlan() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.savePlanLoader = true;
      let res;
      this.planDetails.email = this.getCurrentUser.email;
      this.planDetails.billboards = this.getBbCart;

      if (!this.getBriefsCart) {
        const payload = { mode: "map", data: this.planDetails };
        res = await this.addCampaign(payload);
      } else {
        this.planDetails.briefId = this.getBriefsCart.id;
        const payload = { mode: "brief", data: this.planDetails };
        res = await this.addCampaign(payload);
      }

      if (res) {
        this.savePlanLoader = false;
        this.showCheckoutForm = false;

        for (const bbID of this.getBbCart) {
          await this.removeBbFromCart(bbID);
        }
        reportSuccess("Campaign created successfully");

        setTimeout(() => this.$router.push({ path: "/campaigns" }), 5000);
      }
      this.savePlanLoader = false;
    },

    showLoading() {
      this.exportProgress = true;
    },
    removeLoading() {
      this.exportProgress = false;
    },
  },

  async mounted() {
    await this.fetchBbCart();
    await this.getBillboards();
  },
};
</script>

<style scoped>
#driver {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-btn {
  margin: 10px;
  height: 48px !important;
  width: 90%;
  text-transform: none;
}

.active {
  background: #f68625 !important;
}
.v-data-table__expanded__content {
  background-color: antiquewhite;
}
.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.v-card {
  font-family: Manrope;
}

.v-data-table-header {
  background: #f9f9fa;
}

#priceDriver {
  font-family: Manrope;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
